import React from 'react'
import './home.css'
import imgPersonLap from '../img/Group.png'
import { Link, useNavigate } from 'react-router-dom'
import imgPlace from '../img/place.png'
import webApp from '../img/web-dep.png'
import itSup from '../img/it-sup.png'
import seo from '../img/seo-img.png'
import graph from '../img/graphqp.png'
import man1 from '../img/man1.png'
import man2 from '../img/man2.png'
import man3 from '../img/man4.png'
import About from './About.js'

const Home = () => {
    const nav = useNavigate();
    return (
        <div className="home">

            <div className="weaui">
                <div className="eaow">
                    <div className="wej">
                        <h1>
                            Your Business Transformation Partner
                        </h1>
                        <p>At Knoxo, we empower businesses of all sizes with tailored IT solutions, addressing inefficiencies like inventory management and staff organization to drive growth, scalability, and competitiveness. Knoxo Tech is your partner for transformation and success. </p>
                        <button onClick={() => { nav('/contact') }}>Get Started</button>
                    </div>
                    <div className="hwe">
                        <img src={imgPersonLap} alt="" />
                    </div>
                </div>
                <div className="ek">
                    <div className="wejipart">

                        <div className="cwej1">
                            <span>
                                <h1>20+</h1>
                                <p>Successful Campaigns <br /> Done</p>
                            </span>
                        </div>
                        <div className="cwej1">
                            <span>
                                <h1>100%</h1>
                                <p>Project Complete on <br /> Time</p>
                            </span>
                        </div>
                        <div className="cwej1">
                            <span>
                                <h1>12</h1>
                                <p>Successful Campaigns <br /> Delivered</p>
                            </span>
                        </div>
                        <div className="cwej1">
                            <span>
                                <h1>3+</h1>
                                <p>Years of Marketing <br /> Mastery</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------- */}

            <div className="services-provider">
                <div className="ser">
                    <h1>Services we provide</h1>
                </div>
                <div className="pro-ser">
                    <div className="sess-cards">
                        <div className="see-card">
                            <div className="itm">
                                <img src={webApp} alt="" />
                                <h1>Web & App Development</h1>
                                <p>Custom websites and applications.</p>
                            </div>
                            <div className="ka">
                                <Link to={'web-app'}>Learn More</Link>
                            </div>
                        </div>

                        <div className="see-card">
                            <div className="itm">
                                <img src={seo} alt="" />
                                <h1>SEO & Digital Marketing</h1>
                                <p>Boost visibility and traffic.</p>
                            </div>
                            <div className="ka">
                                <Link to={'seo-markting'}>Learn More</Link>
                            </div>
                        </div>

                        <div className="see-card">
                            <div className="itm">
                                <img src={graph} alt="" />
                                <h1>Graphic Design</h1>
                                <p>Creative branding and visuals.</p>
                            </div>
                            <div className="ka">
                                <Link to={'graph-des'}>Learn More</Link>
                            </div>
                        </div>

                        <div className="see-card">
                            <div className="itm">
                                <img src={itSup} alt="" />
                                <h1>IT Solutions & Support</h1>
                                <p>Streamline operations and growth.</p>
                            </div>
                            <div className="ka">
                                <Link to={'it-soln'}>Learn More</Link>
                            </div>
                        </div>



                    </div>

                </div>
            </div>

            <div className="voices">
                <div className="vocies-tp">
                    <div className="ioeew">
                        <h1>Voices of Success:</h1>
                        <h1>What Our Partners Say</h1>
                    </div>
                </div>
                <div className="all-cont">
                    <div className="some-voices">
                        <div className="soid">
                            <div className="eaij">
                                <img src={man1} alt="amnas" />
                            </div>
                            <div className="jweoi">
                                <h2>Dr. Vivekanand Pandey</h2>
                                <p>CA at Firm</p>
                            </div>
                        </div>
                        <div className="eaoooo">
                            <p>Knoxo's strategies helped us expand our market and scale operations for growth.</p>
                        </div>
                    </div>

                    <div className="some-voices">
                        <div className="soid">
                            <div className="eaij">
                                <img src={man2} alt="amnas" />
                            </div>
                            <div className="jweoi">
                                <h2>Alnaz</h2>
                                <p>Manager at Hyphun Technologies</p>
                            </div>
                        </div>
                        <div className="eaoooo">
                            <p>Their IT solutions transformed our inventory system, allowing us to focus on business growth.</p>
                        </div>
                    </div>

                    <div className="some-voices">
                        <div className="soid">
                            <div className="eaij">
                                <img src={man3} alt="amnas" />
                            </div>
                            <div className="jweoi">
                                <h2>Pratik Kapasi</h2>
                                <p>Founder of HeyCoach</p>
                            </div>
                        </div>

                        <div className="eaoooo">
                            <p>Knoxo Tech improved staff management, streamlining operations and driving significant growth for our business.</p>
                        </div>
                    </div>

                    <div className="some-voices">
                        <div className="soid">
                            <div className="eaij">
                                <img src={man3} alt="amnas" />
                            </div>
                            <div className="jweoi">
                                <h2>Pratik Kapasi</h2>
                                <p>Founder of HeyCoach</p>
                            </div>
                        </div>

                        <div className="eaoooo">
                            <p>Knoxo Tech improved staff management, streamlining operations and driving significant growth for our business.</p>
                        </div>
                    </div>
                    
                </div>
            </div>

            {/* investing plan */}

            <div className="investment-plan">
                <div className="insv">
                    <h1>Invest in Success</h1>
                </div>
                <div className="card-plan">
                    <div className="card-invst">
                        <h1> Expand Your Market Here</h1>
                        <p>Unlock new opportunities and reach a broader audience with Knoxo Tech's market-expanding solutions. Whether it's integrating e-commerce platforms or developing tailored marketing strategies using data analytics, we provide IT solutions to boost your market presence and sales. Your business will no longer be confined by its current limits.</p>
                    </div>

                    <div className="card-invst">
                        <h1>Make Your Management Easier</h1>
                        <p>Managing business operations shouldn't be complicated. Knoxo Tech offers customized software to simplify tasks like staff management, inventory tracking, and customer engagement. Our solutions are designed to enhance operational efficiency, giving you more time to focus on growth.</p>

                    </div>

                    <div className="card-invst">
                        <h1>Bring Something New to the Market</h1>
                        <p>Innovation is key to staying relevant in today's business world. Knoxo Tech helps you launch new products or services with cutting-edge IT tools that make the introduction process seamless. Whether it's software for product tracking or customer feedback integration, we ensure you stand out in the market with ease.
                        </p>
                    </div>

                </div>
            </div>

            {/* trusted brands */}
{/* 
            <div className="brand-trust">
                <div className="hd-trust-brand">
                    <h1>Trusted By Renowned Brands</h1>
                </div>
                <div className="brand-names">
                    <div className="brands-abt">
                        <img src={imgVec1} alt="" />
                        <p>logoipsum</p>
                    </div>

                    <div className="brands-abt">
                        <img src={imgVec2} alt="" />
                        <p>logoipsum</p>
                    </div>

                    <div className="brands-abt">
                        <img src={imgVec1} alt="" />
                        <p>logoipsum</p>
                    </div>

                    <div className="brands-abt">
                        <img src={imgVec2} alt="" />
                        <p>logoipsum</p>
                    </div>

                    <div className="brands-abt">
                        <img src={imgVec1} alt="" />
                        <p>logoipsum</p>
                    </div>

                    <div className="brands-abt">
                        <img src={imgVec2} alt="" />
                        <p>logoipsum</p>
                    </div>
                </div>
            </div> */}


{/* <About/> */}
            {/* lst part */}

            <div className="last-box">
                <div className="box-cet">
                    <div className="cet-lt">
                        <h1>Ready to redefine <br /> your brand's journey?</h1>
                        <p>Get Started with Knoxo Now</p>
                        <button onClick={() =>{nav('/contact')}}>Get Started</button>
                    </div>
                    <div className="box-rt">
                        <img src={imgPlace} alt="" />
                    </div>
                </div>
            </div>




        </div>
    )
}

export default Home